<template>
  <div>
    <BlockViewer header="Pré Visualização de Orçamento" >
      <div class="grid grid-nogutter surface-section text-800">
        <div class="col-12 md:col-6 p-6 text-center md:text-left flex align-items-center ">
          <section>
            <span class="block text-6xl font-bold mb-1">Maria da Silva</span>
            <div class="text-6xl text-primary font-bold mb-3">120kwp</div>
            <p class="mt-0 mb-4 text-700 line-height-3">01/09/2022</p>

            <Button label="Editar Orçamento" type="button" class="mr-3 p-button-raised"></Button>
            <Button label="Imprimir" type="button" class="p-button-outlined"></Button>
          </section>
        </div>
        <div class="col-12 md:col-6 overflow-hidden">
          <img src="demo/images/blocks/hero/usina-rural.png" alt="Image" class="md:ml-auto block md:h-full" style="clip-path: polygon(8% 0, 100% 0%, 100% 100%, 0 100%)">
        </div>
      </div>
    </BlockViewer>

    <BlockViewer header="Dados do Cliente" >
      <div class="surface-section px-4 py-8 md:px-6 lg:px-8 text-center">
        <div class="mb-3 font-bold text-2xl">
          <span class="text-900">Maria da Silva, </span>
          <span class="text-blue-600">Belo Horizonte, MG</span>
        </div>
        <div class="text-700 text-sm mb-6">Pessoa Física. 000.000.000-00</div>
        <div class="grid">
          <div class="col-12 md:col-4 mb-4 px-5">
                        <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                            <i class="pi pi-send text-4xl text-blue-500"></i>
                        </span>
            <div class="text-900 mb-3 font-medium">Logradouro testes 001</div>
            <span class="text-700 text-sm line-height-3">Bairro Industrial. 000.000-00</span>
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
                        <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                            <i class="pi pi-lock text-4xl text-blue-500"></i>
                        </span>
            <div class="text-900 mb-3 font-medium">Status Engenharia</div>
            <span class="text-700 text-sm line-height-3">Aprovação. 10/09/2022</span>
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
                        <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                            <i class="pi pi-check-circle text-4xl text-blue-500"></i>
                        </span>
            <div class="text-900 mb-3 font-medium">Status Financeiro</div>
            <span class="text-700 text-sm line-height-3">Aprovação. 11/09/2022</span>
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
                        <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                            <i class="pi pi-dollar text-4xl text-blue-500"></i>
                        </span>
            <div class="text-900 mb-3 font-medium">Banco do Brasil</div>
            <span class="text-700 text-sm line-height-3">Financiamento. 60 vezes</span>
          </div>
          <div class="col-12 md:col-4 mb-4 px-5">
                        <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                            <i class="pi pi-globe text-4xl text-blue-500"></i>
                        </span>
            <div class="text-900 mb-3 font-medium">Transportadora Braspres</div>
            <span class="text-700 text-sm line-height-3">Previsão Entrega 01/11/2022</span>
          </div>
          <div class="col-12 md:col-4 md:mb-4 mb-0 px-3">
                        <span class="p-3 shadow-2 mb-3 inline-block surface-card" style="border-radius: 10px">
                            <i class="pi pi-phone text-4xl text-blue-500"></i>
                        </span>
            <div class="text-900 mb-3 font-medium">Contato Cliente</div>
            <span class="text-700 text-sm line-height-3">00.0000-0000.</span>
          </div>
        </div>
      </div>
    </BlockViewer>


    <BlockViewer header="Dados do Sistema"   containerClass="surface-section px-4 py-8 md:px-6 lg:px-8">
      <div class="surface-section">
        <div class="font-medium text-3xl text-900 mb-3">Consumo médio do cliente: 600 KWh</div>
        <div class="text-500 mb-5">Potência do sistema: 6,51 KWp</div>
        <ul class="list-none p-0 m-0">
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Tarifa de Energia</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">0,86</div>
            <div class="w-6 md:w-2 flex justify-content-end">
              <!--                <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>-->
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">R$ / KWh</div>
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Tipo de Conexão</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">
              <Chip label="Monofásica" class="mr-2"></Chip>
              <!--                <Chip label="Drama" class="mr-2"></Chip>-->
              <!--                <Chip label="Thriller"></Chip>-->
            </div>
            <div class="w-6 md:w-2 flex justify-content-end">
              <Button label="Editar" icon="pi pi-pencil" class="p-button-text"></Button>
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Data de Validade</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">31/12/2022</div>
            <div class="w-6 md:w-2 flex justify-content-end">
              <Button label="Editar" icon="pi pi-pencil" class="p-button-text"></Button>
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Modelo de Placa</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Produto Testes 001</div>
            <div class="w-6 md:w-2 flex justify-content-end">
              <Button label="Editar" icon="pi pi-pencil" class="p-button-text"></Button>
            </div>
          </li>
          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Inversores</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1 line-height-3">
              Produto Testes 900</div>
            <div class="w-6 md:w-2 flex justify-content-end">
              <Button label="Editar" icon="pi pi-pencil" class="p-button-text"></Button>
            </div>
          </li>

          <li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Tipo de Estrutura</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">Colonial</div>
            <div class="w-6 md:w-2 flex justify-content-end">
              <!--                <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>-->
              <!--                <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">KWh</div>-->
            </div>
          </li>

          <li class="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap">
            <div class="text-500 w-6 md:w-2 font-medium">Geração Média Mensal</div>
            <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">645,74</div>
            <div class="w-6 md:w-2 flex justify-content-end">
              <!--                <Button label="Edit" icon="pi pi-pencil" class="p-button-text"></Button>-->
              <div class="text-900 w-full md:w-8 md:flex-order-0 flex-order-1">KWh</div>
            </div>
          </li>

        </ul>
      </div>
    </BlockViewer>


    <BlockViewer header="Valores"  >
      <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
        <div class="grid">
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Equipamentos</span>
                  <div class="text-900 font-medium text-xl">R$ 148.000,00</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-shopping-cart text-blue-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium">23 em estoque </span>
              <!--                            <span class="text-500">since last visit</span>-->
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Serviços</span>
                  <div class="text-900 font-medium text-xl">R$ 21.100,00</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-map-marker text-orange-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium">%52+ </span>
              <span class="text-500">equipe disponível</span>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Financiamento e Taxas</span>
                  <div class="text-900 font-medium text-xl">CEF</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-inbox text-cyan-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium">14%</span>
              <span class="text-500"> R$ 2.700,00</span>
            </div>
          </div>
          <div class="col-12 md:col-6 lg:col-3">
            <div class="surface-card shadow-2 p-3 border-round">
              <div class="flex justify-content-between mb-3">
                <div>
                  <span class="block text-500 font-medium mb-3">Valor </span>
                  <div class="text-900 font-medium text-xl">Total</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-purple-100 border-round" style="width:2.5rem;height:2.5rem">
                  <i class="pi pi-dollar text-purple-500 text-xl"></i>
                </div>
              </div>
              <span class="text-green-500 font-medium">R$ 180.000,00 </span>
              <!--                            <span class="text-500">responded</span>-->
            </div>
          </div>
        </div>
      </div>
    </BlockViewer>

  </div>
</template>

<script>
import BlockViewer from "@/BlockViewer";
export default {
  name: 'Free',
  components: {BlockViewer},
  data() {
    return {

      checked: false
    }
  }
}
</script>
